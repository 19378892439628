export default function OverivewStats(props) {
  const { draft, submitted, fulfilled, sales } = props;

  return (
    <div className="stats stats-vertical lg:stats-horizontal shadow w-full border-solid border-2">
      <div className="stat">
        <div className="stat-title">Draft Orders</div>
        <div className="stat-value">{nFormatter(draft)}</div>
        <div className="stat-desc">Currently</div>
      </div>

      <div className="stat">
        <div className="stat-title">Submitted Orders</div>
        <div className="stat-value">{nFormatter(submitted)}</div>
        <div className="stat-desc">This month</div>
      </div>

      <div className="stat">
        <div className="stat-title">Fulfilled Orders</div>
        <div className="stat-value">{nFormatter(fulfilled)}</div>
        <div className="stat-desc">This month</div>
      </div>

      <div className="stat">
        <div className="stat-title">Sales</div>
        <div className="stat-value">{formatter.format(sales / 100)}</div>
        <div className="stat-desc">This month</div>
      </div>
    </div>
  );
}

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
  notation: "compact",
});

function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}
