import { useState, useEffect } from "react";
import { Combobox } from "@headlessui/react";
import {
  ASSOCIATE_ORDER_CUSTOMER,
  ASSOCIATE_ORDER_ADDRESS,
} from "../../utils/mutations";
import { ORDER, FIND_CUSTOMERS, LIST_ADDRESSES } from "../../utils/queries";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import toast from "react-hot-toast";

export default function AssociateForm(props) {
  const { orderId, type, customerId } = props;

  switch (type) {
    case "customer":
      return <CustomerForm orderId={orderId} />;
    case "billing":
      return customerId ? (
        <AddressForm orderId={orderId} customerId={customerId} type={type} />
      ) : (
        <></>
      );
    case "shipping":
      return customerId ? (
        <AddressForm orderId={orderId} customerId={customerId} type={type} />
      ) : (
        <></>
      );
    default:
      return <></>;
  }
}

function CustomerForm(props) {
  const { orderId } = props;

  const [customers, setCustomers] = useState([]);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerId, setCustomerId] = useState(null);

  const [findCustomers] = useLazyQuery(FIND_CUSTOMERS, {
    onCompleted: (data) => {
      setCustomers(data.list);
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const [associateOrderCustomer] = useMutation(ASSOCIATE_ORDER_CUSTOMER, {
    onCompleted: () => {
      toast.success("Customer associated successfully");
      setSelectedCustomer(null);
      setCustomerId(null);
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: ORDER, variables: { orderId } }],
  });

  const handleAssociateCustomer = (e) => {
    e.preventDefault();
    if (selectedCustomer) {
      associateOrderCustomer({
        variables: {
          inputs: {
            id: orderId,
            customer: customerId,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (selectedCustomer) {
      if (customers.length > 0) {
        const customer = customers.find(
          (customer) => customer.name === selectedCustomer
        );
        setCustomerId(customer?.id);
      }
    }
  }, [selectedCustomer, customers]);

  return (
    <form onSubmit={handleAssociateCustomer}>
      <div className="flex flex-col">
        <Combobox
          name="customer"
          value={selectedCustomer?.id}
          onChange={setSelectedCustomer}
          nullable
        >
          <div className="relative">
            <Combobox.Input
              placeholder="Find using name or email"
              className="input input-bordered w-full"
              onChange={(event) => {
                findCustomers({
                  variables: { email: event.target.value },
                });
              }}
            />
            <Combobox.Options className="absolute mt-1 w-auto divide-y-2 border-solid border-2 bg-white z-10">
              {customers.map((customer) => (
                <Combobox.Option
                  key={customer.id}
                  value={customer.name}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 px-4 ${
                      active ? "bg-accent text-white" : "text-gray-900"
                    }`
                  }
                >
                  <div className="flex gap-4 content-center md:col-span-2">
                    <div>
                      <span className="font-bold">{customer.name}</span>
                      <br />
                      <span>{customer.email}</span>
                      <br />
                      <span>{customer.address.phone}</span>
                      <br />
                      <span>
                        {customer.address.suburb} / {customer.address.state} /{" "}
                        {customer.address.postcode}
                      </span>
                    </div>
                  </div>
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </div>
        </Combobox>
      </div>
      <div className="flex justify-start mt-2">
        <button type="submit" className="btn btn-sm btn-outline">
          Select
        </button>
      </div>
    </form>
  );
}

function AddressForm(props) {
  const { orderId, customerId, type } = props;

  const [addresses, setAddresses] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressId, setAddressId] = useState(null);

  useQuery(LIST_ADDRESSES, {
    variables: { customerId },
    onCompleted: (data) => {
      setAddresses(data.list);
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const [associateOrderAddress] = useMutation(ASSOCIATE_ORDER_ADDRESS, {
    onCompleted: () => {
      toast.success("Address associated successfully");
      setSelectedAddress(null);
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: ORDER, variables: { orderId } }],
  });

  const filteredAddresses =
    query === ""
      ? addresses
      : addresses.filter((address) => {
          return (
            address.street.toLowerCase().includes(query.toLowerCase()) ||
            address.phone.includes(query)
          );
        });

  const handleAssociateAddress = (e) => {
    e.preventDefault();
    if (selectedAddress) {
      if (type === "billing") {
        associateOrderAddress({
          variables: {
            inputs: {
              id: orderId,
              billing: addressId,
            },
          },
        });
      } else if (type === "shipping") {
        associateOrderAddress({
          variables: {
            inputs: {
              id: orderId,
              shipping: addressId,
            },
          },
        });
      }
    }
  };

  useEffect(() => {
    if (selectedAddress) {
      const customer = addresses.find(
        (address) => address.street === selectedAddress
      );
      setAddressId(customer?.id);
    }
  }, [selectedAddress, addresses]);

  return (
    <form onSubmit={handleAssociateAddress}>
      <div className="flex flex-col">
        <label htmlFor="address" className="text-sm font-semibold">
          {type === "billing"
            ? "Update billing Address"
            : "Update shipping Address"}
        </label>
        <Combobox
          name="address"
          value={selectedAddress?.id}
          onChange={setSelectedAddress}
          nullable
        >
          <div className="relative">
            <Combobox.Input
              placeholder="Find by street or phone"
              className="input input-bordered w-full"
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Options className="absolute mt-1 w-auto h-auto divide-y-2 border-solid border-2 bg-white">
              {filteredAddresses.map((address) => (
                <Combobox.Option
                  key={address.id}
                  value={address.street}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 px-4 z-50 ${
                      active ? "bg-accent text-white" : "text-gray-900 bg-white"
                    }`
                  }
                >
                  <span className="text-sm font-semibold">{address.phone}</span>
                  <br />
                  <span>
                    {address.street}
                    {address.additional && `, ${address.additional}`}
                  </span>
                  <br />
                  <span>
                    {address.suburb}, {address.state} {address.postcode}
                  </span>
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </div>
        </Combobox>
      </div>
      <div className="flex justify-start mt-2">
        <button type="submit" className="btn btn-sm btn-outline">
          Select
        </button>
      </div>
    </form>
  );
}
