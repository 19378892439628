import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        email
        role
      }
    }
  }
`;

export const RESET = gql`
  mutation Reset($email: String!) {
    reset(email: $email)
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($inputs: user) {
    updated: updateProfile(inputs: $inputs)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($password: String!) {
    updated: changePassword(password: $password)
  }
`;

export const CREATE_ORDER_CUSTOMER = gql`
  mutation Mutation($orderId: ID!, $inputs: customer) {
    created: createOrderCustomer(orderId: $orderId, inputs: $inputs)
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($inputs: customer) {
    created: createCustomer(inputs: $inputs)
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($inputs: customer) {
    updated: updateCustomer(inputs: $inputs)
  }
`;

export const CREATE_CUSTOMER_ADDRESS = gql`
  mutation CreateCustomerAddress($inputs: address) {
    created: createCustomerAddress(inputs: $inputs)
  }
`;

export const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation UpdateCustomerAddress($inputs: address) {
    updated: updateCustomerAddress(inputs: $inputs)
  }
`;

export const DRAFT_ORDER = gql`
  mutation DraftOrder($inputs: draft) {
    created: draftOrder(inputs: $inputs)
  }
`;

export const SUBMIT_ORDER = gql`
  mutation SubmitOrder($orderId: ID!) {
    updated: submitOrder(id: $orderId)
  }
`;

export const REVERT_ORDER = gql`
  mutation RevertOrder($orderId: ID!) {
    updated: revertOrder(id: $orderId)
  }
`;

export const DELETE_ORDER = gql`
  mutation DeleteOrder($orderId: ID!) {
    deleted: deleteOrder(id: $orderId)
  }
`;

export const CHANGE_ORDER_INFO = gql`
  mutation ChangeOrderInfo($inputs: order) {
    updated: changeOrderInfo(inputs: $inputs)
  }
`;

export const ASSOCIATE_ORDER_CUSTOMER = gql`
  mutation AssociateOrderCustomer($inputs: order) {
    updated: associateOrderCustomer(inputs: $inputs)
  }
`;

export const ASSOCIATE_ORDER_ADDRESS = gql`
  mutation AssociateOrderAddress($inputs: order) {
    updated: associateOrderAddress(inputs: $inputs)
  }
`;

export const ADD_ORDER_ITEM = gql`
  mutation AddOrderItem($inputs: item) {
    created: addOrderItem(inputs: $inputs)
  }
`;

export const UPDATE_ORDER_ITEM = gql`
  mutation UpdateOrderItem($inputs: item) {
    updated: updateOrderItem(inputs: $inputs)
  }
`;

export const REMOVE_ORDER_ITEM = gql`
  mutation RemoveOrderItem($inputs: item) {
    deleted: removeOrderItem(inputs: $inputs)
  }
`;
