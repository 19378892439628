import logo from "../logo-alt.svg";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/Auth";
import { Helmet } from "react-helmet-async";
import { Toaster } from "react-hot-toast";

export default function UserLayout(props) {
  const { user } = useContext(AuthContext);

  const year = new Date().getFullYear();

  return (
    <div className="bg-base-100">
      <header className="sticky top-0 z-10 shadow-lg">
        <Toaster />
        <Helmet>
          <title>{props.title}</title>
        </Helmet>
        <div className="navbar bg-neutral py-4 px-4">
          <div className="container flex justify-between max-w-7xl mx-auto">
            <div className="flex">
              <Link to="/">
                <img src={logo} alt="App logo" className="w-48" />
              </Link>
            </div>
            <div>{user ? <UserNav /> : <PublicNav />}</div>
          </div>
        </div>
      </header>
      <main>
        <div className="container max-w-7xl mx-auto p-4 py-8">
          {props.children}
        </div>
      </main>
      <footer>
        <div className="footer footer-center max-w-7xl p-4 text-base-content border-t-2 mx-auto">
          <div>
            <p>
              Copyright © {year} - All right reserved by{" "}
              <a
                href="https://aimexaustralia.com.au"
                target="_blank"
                rel="noreferrer"
              >
                Aimex Australia
              </a>{" "}
              - Powered by{" "}
              <a
                href="https://logicsync.com.au"
                target="_blank"
                rel="noreferrer"
              >
                LogicSync
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

function UserNav(props) {
  const { logout } = useContext(AuthContext);

  return (
    <div className="flex gap-2">
      <Link to="/" className="btn btn-square text-accent">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
          />
        </svg>
      </Link>
      <div className="dropdown dropdown-end">
        <label tabIndex="0" className="btn btn-accent btn-outline">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block w-5 h-5 stroke-current"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
            ></path>
          </svg>
        </label>
        <ul
          tabIndex="0"
          className="dropdown-content menu p-2 shadow bg-white rounded-box w-52 mt-6"
        >
          <li>
            <Link
              className="uppercase text-secondary font-semibold"
              to="/profile"
            >
              My Profile
            </Link>
          </li>
          <li>
            <div
              className="uppercase text-secondary font-semibold"
              onClick={() => logout()}
            >
              Logout
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

function PublicNav(props) {
  return (
    <div className="flex-none">
      <Link to="/login" className="btn btn-primary">
        Login
      </Link>
    </div>
  );
}
