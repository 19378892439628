import { gql } from "@apollo/client";

export const PROFILE = gql`
  query Profile {
    profile {
      id
      email
      name
    }
  }
`;

export const VENDOR = gql`
  query Vendor($vendorId: ID!) {
    vendor(id: $vendorId) {
      id
      name
      email
      tier
      dealer
      entity
      abn
      address
      sources {
        id
        name
        url
        default
      }
      owned
      status
      created
      updated
    }
  }
`;

export const VENDOR_STATS = gql`
  query VendorStats {
    stats: vendorStats {
      vendor
      draftOrdersCount
      submittedOrdersCount
      fulfilledOrdersCount
      salesThisMonth
    }
  }
`;

export const FIND_PRODUCTS = gql`
  query FindProducts($title: String!) {
    list: findProducts(title: $title) {
      id
      title
      sku
      thumbnail
      status
    }
  }
`;

export const FIND_CUSTOMERS = gql`
  query FindCustomer($email: String!) {
    list: findCustomer(email: $email) {
      id
      name
      email
      address {
        phone
        suburb
        state
        postcode
        country
      }
    }
  }
`;

export const LIST_ADDRESSES = gql`
  query ListAddresses($customerId: ID!) {
    list: listAddresses(id: $customerId) {
      id
      type
      phone
      company
      street
      additional
      suburb
      state
      postcode
      country
      note
      default
    }
  }
`;

export const ORDERS = gql`
  query Orders($status: String, $vendor: ID) {
    orders(status: $status, vendor: $vendor) {
      id
      number
      ref
      date
      vendor {
        id
        dealer
      }
      source {
        name
      }
      customer {
        name
        email
      }
      total
      status
      created
      updated
    }
  }
`;

export const ORDER = gql`
  query Order($orderId: ID!) {
    order(id: $orderId) {
      id
      number
      ref
      date
      status
      total
      vendor {
        id
        dealer
        address
        abn
      }
      source {
        id
        name
        url
      }
      customer {
        name
        email
        id
      }
      billing {
        id
        type
        phone
        company
        street
        additional
        suburb
        state
        postcode
        country
        note
      }
      shipping {
        id
        type
        phone
        company
        street
        additional
        suburb
        state
        postcode
        country
        note
      }
      items {
        id
        description
        price
        quantity
        amount
        product {
          id
          title
          sku
          thumbnail
        }
      }
      note
      tracking {
        code
        carrier
        cost
      }
    }
  }
`;

export const AVAILABLE_VENDORS = gql`
  query AvailableVendors {
    list: availableVendors {
      id
      dealer
      sources {
        id
        name
        url
        default
      }
    }
  }
`;

export const CUSTOMER = gql`
  query Customer($customerId: ID!) {
    customer(id: $customerId) {
      id
      name
      email
      address {
        id
        phone
        company
        street
        additional
        suburb
        state
        postcode
        country
        note
        type
      }
      addresses {
        id
        type
        phone
        company
        street
        additional
        suburb
        state
        postcode
        country
        note
        default
        customer
      }
      transactions
      purchases {
        id
        product {
          id
          title
          sku
          thumbnail
        }
        description
        price
        quantity
        amount
        order {
          id
          number
          date
          status
        }
      }
      created
      updated
    }
  }
`;

export const ADDRESS = gql`
  query CustomerAddress($addressId: ID!) {
    address: customerAddress(id: $addressId) {
      id
      type
      phone
      company
      street
      additional
      suburb
      state
      postcode
      country
      note
      default
      customer
    }
  }
`;
