import { useNavigate, useParams, Navigate } from "react-router-dom";
import UserLayout from "../layout/User";
import HeadSection from "../library/section/Head";
import CustomerForm from "../library/form/Customer";
import AddressForm from "../library/form/Address";
import OrderForm from "../library/form/Order";

function Create(props) {
  const navigate = useNavigate();
  const { type } = useParams();

  return (
    <UserLayout title={`Create ${type}`}>
      <HeadSection heading={`Create ${type}`}>
        <button className="btn btn-outline" onClick={() => navigate(-1)}>
          Back
        </button>
      </HeadSection>
      <section className="pt-8 space-y-8 max-w-md">
        {type && basedOnType(type)}
      </section>
    </UserLayout>
  );
}

export default Create;

const basedOnType = (type) => {
  switch (type) {
    // customer
    case "customer":
      return <CustomerForm mode="create" />;
    // address
    case "address":
      return <AddressForm mode="create" />;
    // order
    case "order":
      return <OrderForm mode="create" />;
    // otherwise
    default:
      return <Navigate to="/" />;
  }
};
