import { useEffect } from "react";
import { VENDOR_STATS } from "../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import UserLayout from "../layout/User";
import HeadSection from "../library/section/Head";
import OverivewStats from "../library/stats/Overview";
import OrdersTable from "../library/table/Orders";
import { Link } from "react-router-dom";

function Home(props) {
  const { loading, data } = useQuery(VENDOR_STATS, {
    onCompleted: () => {
      toast.dismiss();
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    pollInterval: 30000,
  });

  useEffect(() => {
    if (loading) toast.loading("Loading...");
  }, [loading]);

  return (
    <UserLayout title={`Home`}>
      <HeadSection heading="Home">
        <Link to={"/create/order"} className="btn btn-primary">
          New Order
        </Link>
      </HeadSection>
      {data && (
        <section className="pt-8 space-y-8 divide-y-2">
          <div>
            <h2 className="text-xl font-semibold p-2">Oveview</h2>
            <OverivewStats
              draft={data?.stats?.draftOrdersCount}
              submitted={data?.stats?.submittedOrdersCount}
              fulfilled={data?.stats?.fulfilledOrdersCount}
              sales={data?.stats?.salesThisMonth}
            />
          </div>
          <div>
            <h2 className="text-xl font-semibold p-2">My Orders</h2>
            <OrdersTable vendor={data?.stats?.vendor} />
          </div>
        </section>
      )}
    </UserLayout>
  );
}

export default Home;
