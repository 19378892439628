import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { CHANGE_ORDER_INFO } from "../../utils/mutations";
import { ORDER } from "../../utils/queries";
import { useMutation } from "@apollo/client";
import { useEffect } from "react";

export default function NoteForm(props) {
  const { note, orderId } = props;

  const [loader, setLoader] = useState(false);

  const { register, handleSubmit, setValue } = useForm();

  const [changeOrderInfo] = useMutation(CHANGE_ORDER_INFO, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Note updated successfully!");
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [{ query: ORDER, variables: { orderId } }],
  });

  const onSubmit = (data) => {
    setLoader(true);
    const { note } = data.order;
    changeOrderInfo({
      variables: {
        inputs: {
          id: orderId,
          note,
        },
      },
    });
  };

  useEffect(() => {
    if (note) setValue("order.note", note);
  }, [note, setValue]);

  return (
    <div className="p-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <textarea
            name="note"
            id="note"
            className="input input-bordered w-full h-fit"
            {...register("order.note")}
          />
        </div>
        {/* update */}
        <div className="pt-2">
          <button
            className={
              loader
                ? "btn btn-sm btn-outline loading"
                : "btn btn-sm btn-outline"
            }
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
}
