import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ORDER, ORDERS } from "../utils/queries";
import { SUBMIT_ORDER, DELETE_ORDER, REVERT_ORDER } from "../utils/mutations";
import { useQuery, useMutation } from "@apollo/client";
import UserLayout from "../layout/User";
import HeadSection from "../library/section/Head";
import toast from "react-hot-toast";
import AssociateForm from "../library/form/Associate";
import CustomerInfo from "../library/info/Customer";
import AddressInfo from "../library/info/Address";
import ItemForm from "../library/form/Item";
import ItemsList from "../library/list/Items";
import OrderInfo from "../library/info/Order";
import NoteForm from "../library/form/Note";
import TrackingForm from "../library/form/Tracking";

function Order(props) {
  const { id } = useParams();

  const [canDraft, setCanDraft] = useState(true);
  const [canSubmit, setCanSubmit] = useState(false);
  const [vendorId, setVendorId] = useState(null);

  const navigate = useNavigate();

  const { loading, data } = useQuery(ORDER, {
    variables: { orderId: id },
    onCompleted: () => {
      toast.dismiss();
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const [submitOrder] = useMutation(SUBMIT_ORDER, {
    onCompleted: () => {
      toast.dismiss();
      toast.success("Order submitted successfully!");
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    refetchQueries: [
      { query: ORDER, variables: { orderId: id } },
      { query: ORDERS, variables: { status: null, vendor: vendorId } },
    ],
  });

  const [deleteOrder] = useMutation(DELETE_ORDER, {
    onCompleted: () => {
      toast.dismiss();
      toast.success("Order deleted successfully!");
      navigate("/");
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    refetchQueries: [
      { query: ORDERS, variables: { status: null, vendor: vendorId } },
    ],
  });

  const [revertOrder] = useMutation(REVERT_ORDER, {
    onCompleted: () => {
      toast.dismiss();
      toast.success("Order reverted successfully!");
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    refetchQueries: [
      { query: ORDER, variables: { orderId: id } },
      { query: ORDERS, variables: { status: null, vendor: vendorId } },
    ],
  });

  const handleOrderSubmit = () => {
    toast.loading("Submitting order...");
    submitOrder({
      variables: {
        orderId: id,
      },
    });
  };

  const handleOrderDelete = () => {
    toast.loading("Deleting order...");
    window.confirm("Are you sure you want to delete this order?") &&
      deleteOrder({
        variables: {
          orderId: id,
        },
      });
  };

  const handleOrderRevert = () => {
    toast.loading("Reverting order...");
    window.confirm("Are you sure you want to revert this order?") &&
      revertOrder({
        variables: {
          orderId: id,
        },
      });
  };

  useEffect(() => {
    if (loading) toast.loading("Loading...");
  }, [loading]);

  useEffect(() => {
    const status = data?.order?.status;

    setVendorId(data?.order?.vendor?.id);

    if (data?.order) {
      const customer = data?.order?.customer?.id !== null;
      const billing = data?.order?.billing?.id !== null;
      const shipping = data?.order?.shipping?.id !== null;
      const items = data?.order?.items?.length > 0;
      const total = data?.order?.total > 0;

      setCanDraft(data.order.status === "DRAFT");

      setCanSubmit(
        status === "DRAFT" && customer && billing && shipping && items && total
      );
    }
  }, [data]);

  return (
    <UserLayout title={`Order Information`}>
      <HeadSection heading={`Order Information`}>
        {/* create order */}
        {data?.order?.status === "SUBMITTED" && (
          <Link to={"/create/order"} className="btn btn-primary">
            New Order
          </Link>
        )}
        {/* delete */}
        {canDraft && data?.order?.number === null && (
          <button
            className="btn btn-outline btn-error"
            onClick={() => handleOrderDelete()}
          >
            Delete
          </button>
        )}
        {/* submit */}
        {canSubmit && (
          <button
            className="btn btn-primary"
            onClick={() => handleOrderSubmit()}
          >
            Submit
          </button>
        )}
        {/* revert */}
        {data?.order?.status === "SUBMITTED" && (
          <button
            className="btn btn-outline"
            onClick={() => handleOrderRevert()}
          >
            Revert
          </button>
        )}
        <Link to={`/`} className="btn btn-outline">
          Back
        </Link>
      </HeadSection>
      {data && (
        <section className="pt-8 space-y-8">
          <div className="grid grid-flow-row lg:grid-flow-col gap-6">
            <section className="col-span-1 order-2 lg:order-1 max-w-md break-all">
              <div className="grid grid-flow-row gap-6">
                <div>
                  <h2 className="text-xl font-semibold p-2">Customer</h2>

                  {data?.order?.customer?.email ? (
                    <CustomerInfo
                      name={data?.order.customer?.name}
                      email={data?.order.customer?.email}
                    />
                  ) : (
                    <div className="p-2 space-y-4">
                      <AssociateForm type="customer" orderId={id} />
                      <Link
                        to={`/create/customer?order=${id}`}
                        className="btn btn-xs btn-outline"
                      >
                        Add new
                      </Link>
                    </div>
                  )}
                </div>
                <div>
                  <h2 className="text-xl font-semibold p-2">Addresses</h2>
                  <div className="p-2 space-y-4">
                    {data?.order?.customer?.id === null && (
                      <p className="text-gray-700 text-sm">
                        Customer not selected
                      </p>
                    )}
                    {data?.order?.customer?.id && (
                      <Link
                        to={`/create/address/?customer=${data.order.customer.id}`}
                        className="btn btn-xs btn-outline"
                      >
                        Add new
                      </Link>
                    )}
                    {data?.order?.customer?.id && canDraft && (
                      <AssociateForm
                        type="shipping"
                        orderId={id}
                        customerId={data.order.customer.id}
                      />
                    )}
                    {data?.order?.shipping?.id !== null && (
                      <AddressInfo
                        address={data.order.shipping}
                        customerId={data.order.customer.id}
                        type="Shipping"
                      />
                    )}
                    {data?.order?.customer?.id && canDraft && (
                      <AssociateForm
                        type="billing"
                        orderId={id}
                        customerId={data.order.customer.id}
                      />
                    )}
                    {data?.order?.billing?.id !== null && (
                      <AddressInfo
                        address={data.order.billing}
                        customerId={data.order.customer.id}
                        type="Billing"
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>
            <section className="col-span-1 lg:col-span-5 order-3 lg:order-2">
              <h2 className="text-xl font-semibold p-2">Items</h2>
              {canDraft && (
                <ItemForm orderId={id} customer={data?.order?.customer.id} />
              )}
              <ItemsList
                orderId={id}
                items={data?.order?.items}
                mode={canDraft ? "edit" : "view"}
              />
              {data?.order?.status === "SUBMITTED" && (
                <>
                  <h2 className="text-xl font-semibold p-2">Tracking</h2>
                  <TrackingForm
                    orderId={id}
                    code={data?.order?.tracking.code}
                    carrier={data?.order?.tracking.carrier}
                    cost={data?.order?.tracking.cost}
                    disabled={true}
                  />
                </>
              )}
              {data?.order?.status === "FULFILLED" && (
                <>
                  <h2 className="text-xl font-semibold p-2">Tracking</h2>
                  <TrackingForm
                    orderId={id}
                    code={data?.order?.tracking.code}
                    carrier={data?.order?.tracking.carrier}
                    cost={data?.order?.tracking.cost}
                    disabled={true}
                  />
                </>
              )}
              <h2 className="text-xl font-semibold p-2">Note</h2>
              <NoteForm orderId={id} note={data?.order?.note} />
            </section>
            <section className="col-span-1 order-1 lg:order-3">
              <div className="grid grid-flow-row gap-6">
                <div>
                  <h2 className="text-xl font-semibold p-2">Information</h2>
                  <OrderInfo order={data?.order} />
                </div>
              </div>
            </section>
          </div>
        </section>
      )}
    </UserLayout>
  );
}

export default Order;
