import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { DRAFT_ORDER } from "../../utils/mutations";
import { AVAILABLE_VENDORS, ORDERS } from "../../utils/queries";
import { useQuery, useMutation } from "@apollo/client";

export default function OrderForm(props) {
  const { mode } = props;

  const [loader, setLoader] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [sources, setSources] = useState([]);

  const navigate = useNavigate();

  const { register, handleSubmit, setValue, reset } = useForm();

  const { loading } = useQuery(AVAILABLE_VENDORS, {
    onCompleted: (data) => {
      toast.dismiss();
      setVendors(data.list);
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
  });

  // create
  const [createOrder] = useMutation(DRAFT_ORDER, {
    onCompleted: (data) => {
      setLoader(false);
      toast.success("Draft order created!");
      reset();
      navigate(`/order/${data.created}`);
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
    refetchQueries: [
      {
        query: ORDERS,
        variables: { status: null, vendor: null },
      },
    ],
  });

  // submit
  const onSubmit = (data) => {
    setLoader(true);
    const { vendor, source, ref, date } = data.order.draft;
    if (mode === "create") {
      createOrder({
        variables: {
          inputs: { vendor, source, ref, date: new Date(date) },
        },
      });
    }
  };

  useEffect(() => {
    if (loading) toast.loading("Loading...");
  }, [loading]);

  useEffect(() => {
    if (vendors.length > 0) {
      setValue("order.draft.vendor", vendors[0].id);
      setSources(vendors.find((vendor) => vendor.id === vendors[0].id).sources);
    }
  }, [vendors, setValue]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* vendor */}
        {vendors.length > 0 && (
          <div>
            <label htmlFor="vendor" className="label">
              <span className="label-text font-semibold">Vendor</span>
            </label>
            <select
              defaultValue={"Select"}
              className="select select-bordered w-full"
              required
              {...register("order.draft.vendor")}
              onChange={(e) => {
                const vendor = vendors.find(
                  (vendor) => vendor.id === e.target.value
                );
                setSources(vendor.sources);
              }}
              disabled
            >
              {vendors?.map((vendor) => (
                <option key={vendor.id} value={vendor.id}>
                  {vendor.dealer}
                </option>
              ))}
            </select>
          </div>
        )}
        {/* source */}
        {sources.length > 0 && (
          <div>
            <label htmlFor="vendor" className="label">
              <span className="label-text font-semibold">Source</span>
            </label>
            <select
              defaultValue={"Select"}
              className="select select-bordered w-full"
              required
              {...register("order.draft.source")}
            >
              <option disabled>Select</option>
              {sources.map((source) => (
                <option key={source.id} value={source.id}>
                  {source.name} ({truncateString(source.url, 30)})
                </option>
              ))}
            </select>
            <label className="label">
              <span className="label-text-alt">
                Source on which the order was placed
              </span>
            </label>
          </div>
        )}
        {/* ref */}
        <div>
          <label htmlFor="ref" className="label">
            <span className="label-text font-semibold">Ref #</span>
          </label>
          <input
            type="text"
            name="abn"
            id="abn"
            className="input input-bordered w-full"
            {...register("order.draft.ref")}
          />
          <label className="label">
            <span className="label-text-alt">
              Order # from source (Ex. eBay Oder No. / Shopify Order No.)
            </span>
          </label>
        </div>
        {/* date */}
        <div>
          <label htmlFor="date" className="label">
            <span className="label-text font-semibold">Date</span>
          </label>
          <input
            type="date"
            name="abn"
            id="abn"
            className="input input-bordered w-full"
            {...register("order.draft.date")}
            required
          />
          <label className="label">
            <span className="label-text-alt">
              Date on which the order was placed
            </span>
          </label>
        </div>
        {/* submit */}
        <div className="pt-4">
          <button className={loader ? "btn loading" : "btn"}>
            {mode === "update" ? "Update" : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
}

function truncateString(string, limit) {
  if (string.length > limit) {
    return string.substring(0, limit) + "...";
  } else {
    return string;
  }
}
