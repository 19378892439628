import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { RESET } from "../../utils/mutations";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export default function ResetForm() {
  const [loader, setLoader] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  const [doReset, { loading }] = useMutation(RESET, {
    onCompleted: () => {
      setLoader(false);
      toast.success("Password reset successfully, please check your inbox!");
      reset();
    },
    onError: (error) => {
      setLoader(false);
      toast.error(error.message);
    },
  });

  const onSubmit = (data) => {
    const { email } = data.forgot;
    console.log(email.toLowerCase());
    doReset({
      variables: {
        email: email.toLowerCase(),
      },
    });
  };

  useEffect(() => {
    if (loading) setLoader(true);
  }, [loading]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
      <div>
        <label className="label">
          <span className="label-text font-semibold">Email address</span>
        </label>
        <input
          type="email"
          placeholder="john.doe@email.com"
          className="input input-bordered w-full"
          {...register("forgot.email")}
          required
        />
      </div>
      <div className="pt-4">
        <button
          type="submit"
          className={
            loader ? "btn btn-accent w-full loading" : "btn btn-accent w-full"
          }
        >
          Reset Password
        </button>
      </div>
    </form>
  );
}
