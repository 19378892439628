import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./context/Auth";
import Login from "./page/Login";
import Reset from "./page/Reset";
import Home from "./page/Home"; // manage student, view enrolments
import Error from "./page/Error";
import Create from "./page/Create";
import Order from "./page/Order";
import Update from "./page/Update";
import Profile from "./page/Profile";

function App() {
  return (
    <Routes>
      {/* open routes */}
      <Route path="*" element={<Error />} />
      {/* public routes */}
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/reset"
        element={
          <PublicRoute>
            <Reset />
          </PublicRoute>
        }
      />
      {/* vendor routes */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/order/:id"
        element={
          <ProtectedRoute>
            <Order />
          </ProtectedRoute>
        }
      />
      <Route
        path="/create/:type"
        element={
          <ProtectedRoute>
            <Create />
          </ProtectedRoute>
        }
      />
      <Route
        path="/update/:type/:id"
        element={
          <ProtectedRoute>
            <Update />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default App;

function PublicRoute(props) {
  const { user } = useContext(AuthContext);
  // not logged in
  return <>{!user ? props.children : <Navigate to="/" />}</>;
}

function ProtectedRoute(props) {
  const { user } = useContext(AuthContext);
  // admin or manager user
  return (
    <>
      {user && user.data?.role === "VENDOR" ? (
        props.children
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}
