import { UPDATE_ORDER_ITEM, REMOVE_ORDER_ITEM } from "../../utils/mutations";
import { ORDER } from "../../utils/queries";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";

export default function ItemsList(props) {
  const { orderId, items, mode } = props;

  const [updateOrderItem] = useMutation(UPDATE_ORDER_ITEM, {
    onCompleted: () => {
      toast.success("Order item updated");
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: ORDER, variables: { orderId } }],
  });

  const [removeOrderItem] = useMutation(REMOVE_ORDER_ITEM, {
    onCompleted: () => {
      toast.success("Order item removed");
    },
    onError: (error) => {
      toast.error(error.message);
    },
    refetchQueries: [{ query: ORDER, variables: { orderId } }],
  });

  const handlePriceChange = (e, id) => {
    const { value } = e.target;
    updateOrderItem({
      variables: {
        inputs: {
          id,
          price: parseInt(parseFloat(value).toFixed(2) * 100),
          orderId,
        },
      },
    });
  };

  const handleQuantityChange = (e, id) => {
    const { value, name } = e.target;
    console.log(value, name, id);
    updateOrderItem({
      variables: {
        inputs: {
          id,
          quantity: parseInt(value),
          orderId,
        },
      },
    });
  };

  const handleItemRemoval = (id) => {
    removeOrderItem({
      variables: {
        inputs: { id, orderId },
      },
    });
  };

  return (
    <div>
      <ul className="grid grid-flow-row divide-y-2">
        {items &&
          items.map((item, index) => (
            <li
              className="grid sm:grid-cols-2 md:grid-cols-5 xl:grid-cols-6 gap-4 md:gap-2 p-4"
              key={index}
            >
              {/* product */}
              <div className="flex gap-4 content-center md:col-span-2">
                <div>
                  <img
                    src={thumbnailUri(item.product.thumbnail)}
                    alt={item.product.title}
                    className="w-12 h-12"
                  />
                </div>
                <div>
                  <span className="font-bold">{item.description}</span>
                  <br />
                  <span>{item.product.sku}</span>
                </div>
              </div>
              {/* price */}
              <div className="col-auto flex md:flex-col md:text-center">
                <label className="font-semibold">Price: </label>
                {mode === "edit" ? (
                  <input
                    type="number"
                    min="0.00"
                    max="10000.00"
                    step="0.01"
                    name={`price-${item.id}`}
                    className="input input-bordered input-sm w-24 mx-auto text-center"
                    defaultValue={item.price / 100}
                    onBlur={(e) => handlePriceChange(e, item.id)}
                  />
                ) : (
                  <span>{formatter.format(item.price / 100)}</span>
                )}
              </div>
              {/* quantity */}
              <div className="col-auto flex md:flex-col md:text-center">
                <label className="font-semibold">Qty: </label>
                {mode === "edit" ? (
                  <input
                    type="number"
                    min="1"
                    max="100"
                    step="1"
                    name={`qty-${item.id}`}
                    className="input input-bordered input-sm w-24 mx-auto text-center"
                    defaultValue={item.quantity}
                    onChange={(e) => handleQuantityChange(e, item.id)}
                  />
                ) : (
                  <span>{item.quantity}</span>
                )}
              </div>
              {/* amount */}
              <div className="col-auto flex md:flex-col md:text-center gap-2 md:gap-1">
                <label className="font-semibold">Amount: </label>
                <span>
                  {formatter.format((item.price * item.quantity) / 100)}
                </span>
              </div>
              {/* action */}
              {mode === "edit" && (
                <div className="flex flex-row gap-1 items-center md:pt-2 xl:pt-0 xl:justify-end">
                  <button
                    className="btn btn-sm btn-ghost"
                    onClick={() => handleItemRemoval(item.id)}
                  >
                    Remove
                  </button>
                </div>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
}

const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

const thumbnailUri = (thumbnail) => {
  if (thumbnail)
    return `https://aimexaustralia-app-bucket.s3.ap-southeast-2.amazonaws.com/thumbnail/${thumbnail}`;
  return "https://aimexaustralia-app-bucket.s3.ap-southeast-2.amazonaws.com/thumbnail/placeholder-square.jpg";
};
