import { useNavigate, useParams, Navigate } from "react-router-dom";
import { CUSTOMER, ADDRESS } from "../utils/queries";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import UserLayout from "../layout/User";
import HeadSection from "../library/section/Head";
import CustomerForm from "../library/form/Customer";
import AddressForm from "../library/form/Address";

function Update(props) {
  const navigate = useNavigate();
  const { type, id } = useParams();

  return (
    <UserLayout title={`Update ${type}`}>
      <HeadSection heading={`Update ${type}`}>
        <button className="btn btn-outline" onClick={() => navigate(-1)}>
          Back
        </button>
      </HeadSection>
      <section className="pt-8 space-y-8 max-w-md">
        {type && basedOnType(type, id)}
      </section>
    </UserLayout>
  );
}

export default Update;

const basedOnType = (type, id) => {
  switch (type) {
    // customer
    case "customer":
      return <UpdateCustomer id={id} />;
    // address
    case "address":
      return <UpdateAddress id={id} />;
    // otherwise
    default:
      return <Navigate to="/" />;
  }
};

function UpdateCustomer(props) {
  const { id } = props;

  const { data } = useQuery(CUSTOMER, {
    variables: { customerId: id },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return <CustomerForm mode="update" info={data?.customer} id={id} />;
}

function UpdateAddress(props) {
  const { id } = props;

  const { data } = useQuery(ADDRESS, {
    variables: { addressId: id },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return <AddressForm mode="update" info={data?.address} id={id} />;
}
